import { Injectable, inject } from '@angular/core';

import { DataFormatService } from 'app/core/services/data-format.service';
import { DataModelStoreService } from 'app/core/data-model/services/data-model.store';
import { RecordLinkService } from 'app/core/services/record-link.service';
import { Router } from '@angular/router';
import { getObjValueFromPath } from 'app/core/utils/js-helper';
import { getTextWidth } from 'app/core/utils/canvas-text-helper';
import { tableColumn } from '../models/models';

@Injectable({
  providedIn: 'root',
})
export class TableColumnService {
  private dataModelService: DataModelStoreService = inject(DataModelStoreService);
  private dataFormatService: DataFormatService = inject(DataFormatService);
  private recordLinkService = inject(RecordLinkService);
  private router = inject(Router);
  getDataModelColumn(
    recordType: string,
    path: string,
    overrides?: Partial<tableColumn>,
  ): tableColumn {
    const field = this.dataModelService.getFieldFromPath(recordType, path);
    if (!field) {
      return {
        propertyName: path,
        displayName: path,
      };
    }

    const format = this.dataFormatService.getFormattingForField(field);
    const col: tableColumn = {
      propertyName: path,
      displayName: field.label,
      format: format,
      codeList: field.codeList,
      fieldType: field.fieldType,
      codeSet: field.codeSet,
      displayFunc: (column: tableColumn, row: any, value: any) => {
        return this.dataFormatService.formatFieldValue(value, field);
      },
      ...overrides,
    };
    if (this.dataFormatService.isNumericFormat(format)) {
      col.alignment = 'right';
    }
    if (field.fieldType === 'datetime' && !col.width) {
      col.width = this.getColumnSize(col.displayName, 200);
    }
    if (field.fieldType === 'date' && !col.width) {
      col.width = this.getColumnSize(col.displayName, 120);
    }

    if (path.includes('.')) {
      const field = this.dataModelService.getFieldFromPath(recordType, path);
      if (field && field.recordTypeId !== recordType) {
        const foreignKeyPath = this.dataModelService.getPrimaryKeyFieldFromPath(recordType, path);
        if (foreignKeyPath) {
          const foreignKeyField = this.dataModelService.getFieldFromPath(
            recordType,
            foreignKeyPath,
          );
          if (foreignKeyField) {
            const foreignKeyRecordType = this.dataModelService.getRecordType(
              foreignKeyField.recordTypeId!,
            );
            if (
              foreignKeyRecordType &&
              this.recordLinkService.supportsViewGridLink(foreignKeyRecordType)
            ) {
              col.linkFunc = (column: tableColumn, row: any) => {
                const fkValue = getObjValueFromPath(row, foreignKeyPath);
                if (fkValue) {
                  const link = this.recordLinkService.getRecordTypeViewLink(
                    foreignKeyField.recordTypeId!,
                    fkValue,
                  );
                  if (link) {
                    return this.router.serializeUrl(this.router.createUrlTree([link]));
                  }
                }
              };
            }
            if (foreignKeyField.recordTypeId) {
              const foreignKeyRecordType = this.dataModelService.getRecordType(
                foreignKeyField.recordTypeId,
              );
              col.toolTipFunc = (column: tableColumn, row: any, value: any) => {
                return `View ${foreignKeyRecordType?.displayNameSingular}`;
              };
            }
          }
        }
      }
    }
    return col;
  }
  getColumnSize(headerLabel: string | undefined, baseSize: number) {
    const padding = 24; //Pixels, columns use 12px left and right padding
    const headerWidth = getTextWidth(headerLabel);
    return Math.max((headerWidth ?? baseSize) + padding, baseSize);
  }
}
