var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FieldData, RecordTypeData } from '../meta-data-decorators';
const recordTypeId = 'LossRunConfig';
let LossRunConfig = class LossRunConfig {
    policyholder;
    masterPolicyholder;
    childPolicyholder;
};
__decorate([
    FieldData({ recordTypeId })
], LossRunConfig.prototype, "policyholder", void 0);
__decorate([
    FieldData({ recordTypeId })
], LossRunConfig.prototype, "masterPolicyholder", void 0);
__decorate([
    FieldData({ recordTypeId })
], LossRunConfig.prototype, "childPolicyholder", void 0);
LossRunConfig = __decorate([
    RecordTypeData({
        id: recordTypeId,
        hasBackingTable: false,
        displayNameSingular: 'Loss Run Configuration',
        displayNamePlural: 'Loss Run Configurations',
        dataModelCustomization: {
            allowCustomFields: true,
            preventAddCustomFields: true,
        },
    })
], LossRunConfig);
export { LossRunConfig };
