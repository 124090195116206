export const routeDefs = new Map([
    ['claim', { route: ['claims', '@id'] }],
    ['claimtransaction', { route: ['claims', 'claim-transactions', '@id'] }],
    ['contact', { route: ['contacts', '@id'] }],
    ['policyholder', { route: ['policyholders', '@id'] }],
    ['location', { route: ['locations', '@id'] }],
    ['check', { route: ['checks', '@id'] }],
    ['bankaccount', { route: ['admin', 'bank-accounts', '@id'] }],
    ['claimcheckpayment', { route: ['checks', 'payments', '@id'] }],
    ['incident', { route: ['incidents', '@id'] }],
    ['invoice', { route: ['invoices', '@id'] }],
    ['quote', { route: ['quotes', '@id'] }],
    ['submission', { route: ['submissions', '@id'] }],
    ['renewal', { route: ['renewals', '@id'] }],
    ['policy', { route: ['policies', '@id'] }],
    ['endorsement', { route: ['endorsements', '@id'] }],
    ['policyaudit', { route: ['policy-audits', '@id'] }],
    ['policypayment', { route: ['payments', '@id'] }],
    ['payrollreport', { route: ['payments', 'payroll-worksheet', '@id'] }],
    ['agency', { route: ['agencies', '@id'] }],
    ['carrier', { route: ['carriers', '@id'] }],
    ['employer', { route: ['employers', '@id'] }],
    ['view', { route: ['views', '@id'] }],
    ['lineitemtype', { route: ['line-item-types', '@id'] }],
    ['invoiceappliedpayment', { route: ['payments', '@id'], idProperty: 'sourcePaymentId' }],
    ['autopayplan', { route: ['autopay-plans', '@id'] }],
    ['surpluscalculation', { route: ['accounting', 'surplus-calculations', '@id'] }],
]);
