import { inject, Injectable, Optional, SkipSelf } from '@angular/core';
import { Router } from '@angular/router';
import {
  BehaviorSubject,
  catchError,
  filter,
  first,
  firstValueFrom,
  from,
  map,
  Observable,
  of,
  shareReplay,
  switchMap,
} from 'rxjs';
import { ExtendedUserDetails, UserDetailsPersona } from 'portal-commons/dist/users/models';
import { WebsocketService } from './web-socket.service';
import { AuthUtils } from './auth.utils';
import { CognitoService, ICognitoUserInput } from './cognito.service';
import { RoleCategories } from 'portal-commons/dist/roleEnums';
import { environment } from '../../../environments/environment';
import { SignInResultState } from '../models/idp-state';
import { SessionUrlService } from './session-url.service';
import { ToastNotificationService } from '../notifications/toasts/toast-notification.service';
import { Auth } from 'aws-amplify';
import { HttpClient } from '@angular/common/http';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TenantThemeService } from '../services/tenant-theme.service';
import { EmailPattern } from '../models/globals';
import { WsMessage, WsMessageTypes } from 'portal-commons/dist/ws/model';
import { Role } from 'portal-commons/dist/data-model/record-types/role';
import { TenantsService } from '../tenants/tenants.service';
import { Feature } from 'portal-commons/dist/tenant/feature';
import { CodeSetActionPermission } from 'portal-commons/dist/roles/models';

export interface AuthState {
  isLoggedIn: boolean;
  tenant: string | null;
  id: string | null;
  email: string | null;
  lastToken: string | null;
  role: Role | null;
  agencyId: string | null;
  policyholderIds: string[] | null;
  persona: UserDetailsPersona | null;
  fullName: string | null;
}

const initialAuthState: AuthState = {
  isLoggedIn: false,
  tenant: null,
  id: null,
  email: null,
  lastToken: null,
  role: null,
  persona: null,
  agencyId: null,
  policyholderIds: null,
  fullName: null,
};

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class AuthService {
  private storageTenantKey = 'tenant';
  private storageShowSSOKey = 'show_sso';

  tenantService = inject(TenantsService);
  private readonly _authState = new BehaviorSubject<AuthState>(initialAuthState);

  /** AuthState as an Observable */
  readonly auth$ = this._authState.asObservable();
  private _initialized$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /** Observe the isLoggedIn slice of the auth state */
  readonly _isLoggedIn$ = this.auth$.pipe(
    map((state) => state.isLoggedIn),
    untilDestroyed(this),
  );

  private readonly _authRelay = new BehaviorSubject<string>('');
  readonly authRelay$ = this._authRelay.asObservable();

  /**
   * Constructor
   */
  constructor(
    private _cognitoService: CognitoService,
    private websocketService: WebsocketService,
    private toastNotifications: ToastNotificationService,
    private _router: Router,
    private urlService: SessionUrlService,
    private _httpClient: HttpClient,
    private tenantThemeService: TenantThemeService,
    @Optional() @SkipSelf() parent?: AuthService,
  ) {
    if (parent) {
      throw Error('attempted to create duplicate AuthService');
    }

    // Get the user on creation of this service
    _cognitoService.currentAuthenticatedUser().then(
      async (payload) => {
        if (payload.user !== null) {
          await this.setUser(payload.user);
        } else {
          this.clearUser();
        }
      },
      (err) => {
        console.error('got error getting current currentAuthenticatedUser', err);
        this.clearUser();
      },
    );

    _cognitoService.signOuts$
      .pipe(
        filter((f) => f),
        untilDestroyed(this),
      )
      .subscribe(() => {
        this.clearUser();
      });

    // _cognitoService.signIns$
    //   .pipe(
    //     untilDestroyed(this),
    //     switchMap((payload) => {
    //       if (!payload) {
    //         throw Error('payload was null in signIns$');
    //       }

    //       if (payload.user) {
    //         return from(this.setUser(payload.user));
    //       }

    //       if (payload.fromCode) {
    //         return of(null);
    //       }

    //       return of(null);
    //     }),
    //     catchError((err) => {
    //       console.error('err setting user', err);
    //       return of(err);
    //     })
    //   )
    //   .subscribe({
    //     error: (error) => {
    //       console.error('error setting user', error);
    //     },
    //     complete: () => {
    //       console.warn('signIns$ completed');
    //     },
    //   });

    _cognitoService.refreshToken$.pipe(untilDestroyed(this)).subscribe((payload) => {
      this._authState.next({
        ...this._authState.getValue(),
        lastToken: payload.newToken as string,
      });
    });

    _cognitoService.oauthStates$.pipe(untilDestroyed(this)).subscribe((payload) => {
      if (payload && payload.state) {
        this._authRelay.next(payload.state);
      }
    });
  }

  private clearUser() {
    this._authState.next(initialAuthState);
    this._initialized$.next(true);
  }

  clearTenant() {
    localStorage.removeItem(this.storageTenantKey);
    this._authState.next({ ...this._authState.getValue(), tenant: null });
  }

  setTenant(tenant: string | null) {
    if (tenant) {
      this.setTenantInStorage(tenant);
      this.tenantThemeService.setTenant(tenant);
    } else {
      this.clearTenant();
      this.tenantThemeService.clearTenant();
    }
  }

  private getTenantFromStorage() {
    return localStorage.getItem(this.storageTenantKey);
  }

  getTenant() {
    return this._authState.getValue()?.tenant ?? this.getTenantFromStorage();
  }

  private getShowSSOFromStorage() {
    return localStorage.getItem(this.storageShowSSOKey);
  }

  private clearShowSSOFromStorage() {
    return localStorage.removeItem(this.storageShowSSOKey);
  }

  private setShowSSOInStorage() {
    return localStorage.setItem(this.storageShowSSOKey, 'true');
  }

  getShowSSO() {
    return (this.getShowSSOFromStorage() ?? 'false') === 'true';
  }

  setShowSSO(val: boolean) {
    if (!val) {
      this.clearShowSSOFromStorage();
    } else {
      this.setShowSSOInStorage();
    }
  }

  private setTenantInStorage(tenant: string | undefined | null) {
    if (!tenant) {
      this.clearTenant();
    } else {
      localStorage.setItem(this.storageTenantKey, tenant);
    }
  }

  async getCurrentDetails(
    token: string | null = null,
    signIn?: boolean,
  ): Promise<ExtendedUserDetails | AuthState | undefined> {
    try {
      const authState = this._authState.getValue();

      if (!authState.lastToken && !token) {
        console.error('no token, could not getCurrentDetails');
        return undefined;
      }

      if (authState.isLoggedIn === false || signIn === true) {
        try {
          const userDetailsUri = `/api/session/extended-user-details`;
          const http$ = this._httpClient
            .get<ExtendedUserDetails>(userDetailsUri)
            .pipe(shareReplay(1));
          const userDetails = await firstValueFrom(http$);
          return {
            ...userDetails,
            policyholderIds: userDetails.policyHolders?.map((x) => x.id),
          } as ExtendedUserDetails;
        } catch (err) {
          console.error('Unable to getCurrentDetails', err);
          return undefined;
        }
      } else {
        return authState;
      }
    } catch (err) {
      console.error('Unhandled error in getCurrentDetails', err);
      return undefined;
    }
  }

  private async setUser(user: any, redirectToSignOut = true) {
    if (!user) {
      return;
    }

    const currState = this._authState.getValue();
    if (user.id === currState.id && currState.isLoggedIn) {
      return;
    }
    let id = null;
    let token = null;

    const signInUserSession = user.signInUserSession;
    const idToken = signInUserSession.idToken;
    if (idToken.sub) {
      id = idToken.sub;
    } else if (idToken.payload.sub) {
      id = idToken.payload.sub;
    }

    if (idToken.jwtToken) {
      token = idToken.jwtToken;
    }

    if (!token) {
      token = await this._cognitoService.getToken();
    }

    if (token === null) {
      console.error('token was not set');
      return;
    }

    const preDetailsState = {
      isLoggedIn: true,
      id,
      lastToken: token,
      tenant: this.getTenantFromStorage(),
    } as AuthState;

    this._authState.next(preDetailsState);

    const details = await this.getCurrentDetails(token, true);

    if (!details) {
      console.error('details was null after attempting userData.getExtendedUserDetails');

      if (!redirectToSignOut) {
        throw Error('Unable to authenticate user details');
      }

      this.toastNotifications.error(
        'Unable to establish user details.  Please contact a system administrator.',
      );
      if (environment.inAws) {
        const trySignOut = await this.signOut();
        if (trySignOut) {
          await this._router.navigate(['/sign-out']);
        }
      }

      return;
    }

    const postDetailsState = { ...preDetailsState, ...details };

    this.websocketService.connect(postDetailsState);

    this.websocketService.incomingMessages$
      .pipe(
        untilDestroyed(this),
        filter((f: WsMessage) => f._type === WsMessageTypes.refreshAll),
        switchMap((pleaseRefresh) => {
          if (pleaseRefresh) {
            return from(this._cognitoService.refreshToken());
          }
          return of(null);
        }),
        catchError((err) => {
          console.error('err setting user', err);
          return of(err);
        }),
        shareReplay(1),
      )
      .subscribe({
        error: (error) => {
          console.error('error after receiving please refresh', error);
        },
        complete: () => {
          console.warn('signIns$ completed');
        },
      });

    this._authState.next(postDetailsState);
    this._initialized$.next(true);
  }

  async getLatestToken(): Promise<string | null> {
    const state = this._authState.getValue();
    if (!state.isLoggedIn || !state.lastToken) {
      return null;
    }
    const isTokenExpired = AuthUtils.isTokenExpired(state.lastToken);
    if (isTokenExpired) { console.log('token expired', isTokenExpired); }
    if (state.isLoggedIn && !isTokenExpired) {
      return state.lastToken;
    }

    const newToken = await this._cognitoService.getToken();
    console.log('new Token expiration Date', AuthUtils._getTokenExpirationDate(newToken));
    //console.log('new token expired', AuthUtils.isTokenExpired(newToken));
    this._authState.next({ ...state, lastToken: newToken });
    return newToken;
  }

  federatedSignIn(options: any) {
    return Auth.federatedSignIn(options);
  }

  async setChallengePassword(username: string, tempPassword: string, newPassword: string) {
    const user: ICognitoUserInput = {
      username: this.getCognitoUserName(username),
      password: tempPassword,
    };
    const signin = await this._cognitoService.signIn(user);

    if (signin.challengeName && signin.challengeName === 'NEW_PASSWORD_REQUIRED') {
      try {
        await Auth.completeNewPassword(signin, newPassword);
      } catch (err) {
        console.error(err);
        throw Error('Unable to change password due to error.');
      }
    } else {
      throw Error(
        'Temporary password is no longer active, please initiate Forgot Password flow again',
      );
    }
  }

  getCognitoUserName(inputUserName: string) {
    let cognitoUserName = inputUserName;
    const state = this._authState.getValue();
    const tenant = !!state.tenant ? state.tenant : this.urlService.getTenantFromCurrentUrl();
    if (!EmailPattern.test(inputUserName)) {
      cognitoUserName = `${tenant}-${inputUserName}`;
    }
    return cognitoUserName;
  }

  async signIn(username: string, password: string): Promise<SignInResultState> {
    const state = this._authState.getValue();

    if (
      state &&
      state.isLoggedIn &&
      state.id === username &&
      !AuthUtils.isTokenExpired(state.lastToken)
    ) {
      return SignInResultState.success;
    }

    if (state.isLoggedIn && state.id !== username) {
      await this.signOut();
    }

    const user: ICognitoUserInput = {
      username: this.getCognitoUserName(username),
      password,
    };
    const signin = await this._cognitoService.signIn(user);

    if (signin.challengeName && signin.challengeName === 'NEW_PASSWORD_REQUIRED') {
      return SignInResultState.changePassword;
    }
    try {
      await this.setUser(signin, false);
      return SignInResultState.success;
    } catch (error) {
      console.error('could not set user, error:', error);
      return SignInResultState.fail;
    }
  }

  async signOut(): Promise<boolean> {
    try {
      await this._cognitoService.signOut();
    } catch (error) {
      console.error('Failure in Cognito signOut', error);
      throw error;
    }
    return true;
  }

  async forgotPassword(username: string): Promise<any> {
    return this._cognitoService.forgotPassword(this.getCognitoUserName(username));
  }

  async resetPassword(username: string, newPassword: string, code: string) {
    return this._cognitoService.resetPassword(this.getCognitoUserName(username), newPassword, code);
  }

  async changePassword(oldPassword: string, newPassword: string) {
    return await this._cognitoService.changePassword(oldPassword, newPassword);
  }

  isLoggedIn() {
    return this._authState.getValue().isLoggedIn;
  }

  currentUser() {
    return this._authState.getValue();
  }

  isTrailblazerUser() {
    return this._authState.getValue()?.persona === UserDetailsPersona.TrailblazerUser;
  }

  isUserType(types: UserDetailsPersona[]) {
    return (
      this._authState.getValue().persona !== null &&
      types.includes(this._authState.getValue().persona!)
    );
  }

  isAgencyUser() {
    return this._authState.getValue()?.persona === UserDetailsPersona.AgencyUser;
  }

  isPolicyHolderUser() {
    return this._authState.getValue()?.persona === UserDetailsPersona.PolicyHolderUser;
  }

  isTenantUser() {
    return this._authState.getValue()?.persona === UserDetailsPersona.TenantUser;
  }

  tenantHasFeature(feature: Feature): Observable<boolean> {
    if (!this.isLoggedIn() || !!!this._authState.getValue()?.tenant) {
      return of(false);
    }
    return this.tenantService.getFeatures().pipe(
      map((features) => {
        if (!features) { return false; }
        const match = features?.find(f => f.feature === feature);
        return match !== undefined && match !== null;
      }),
      catchError((error) => {
        console.error(`Unable to check tenant feature ${feature}`, error);
        return of(false);
      }),
      shareReplay(1)
    );
  }

  hasFeatureAccess(category: RoleCategories): boolean {
    return this.hasFeatureAccessImpl(category);
  }

  private hasFeatureAccessImpl(category: RoleCategories): boolean {
    if (!this.isLoggedIn()) {
      return false;
    }
    const hasFeatureAccess = false;
    const catAsStr = category as string;

    const role = this._authState.getValue()?.role;
    if (!role) {
      console.error(
        'user did not have any role, and therefore will have all permissions false.  add a role to the user',
      );
      return false;
    }
    if (role.permissions && role.permissions.length > 0) {
      for (let i = 0; i < role.permissions.length; i++) {
        const category = role.permissions[i];

        if (category && category.id === catAsStr) {
          return category.enabled ?? false;
        }
      }
    } else {
      return false;
    }

    return hasFeatureAccess;
  }

  hasFeatureAccess$(category: RoleCategories): Observable<boolean> {
    return this._initialized$.pipe(
      first(),
      switchMap(() => {
        return of(this.hasFeatureAccessImpl(category));
      }),
    );
  }

  hasPermission(category: RoleCategories, permission: string): boolean {
    return this.hasPermissionImpl(category, permission);
  }

  hasAnyOfCodeSetActionPermission(set: 'contactType' | 'coverage' | 'incidentType', action: 'Create' | 'Edit' | 'View' | 'Delete', values: string[]) {
    if (!this.isLoggedIn()) {
      return false;
    }
    for (const value of values) {
      if (this.hasCodeSetActionPermission(set, action, value)) { return true; }
    }
    return false;
  }

  hasCodeSetActionPermission(set: 'contactType' | 'coverage' | 'incidentType', action: 'Create' | 'Edit' | 'View' | 'Delete', value: string) {
    if (!this.isLoggedIn()) {
      return false;
    }
    const hasPermission = false;
    const role = this._authState.getValue()?.role;
    if (!role) { return false; }
    const matchType = ((role as any)[`${set}Permissions`] as CodeSetActionPermission[])?.find(f => f.id === value || f.id === '*');
    if (matchType) {
      return ((matchType as any)[`can${action}`]) ?? false;
    }
    return hasPermission;
  }

  private hasPermissionImpl(category: RoleCategories, permission: string): boolean {
    if (!this.isLoggedIn()) {
      return false;
    }
    const catAsStr = category as string;
    const hasPermission = false;

    const role = this._authState.getValue()?.role;
    if (!role) {
      return false;
    }
    if (role.permissions && role.permissions.length > 0) {
      for (let i = 0; i < role.permissions.length; i++) {
        const category = role.permissions[i];
        if (category && category.id === catAsStr) {
          if (!category.enabled) {
            return false;
          }
          if (category.granularPermissions && category.granularPermissions.length > 0) {
            for (let j = 0; j < category.granularPermissions.length; j++) {
              const catPerm = category.granularPermissions[j];
              if (catPerm && permission === catPerm.id) {
                return catPerm.enabled ?? false;
              }
            }
          }
        }
      }
    } else {
      return false;
    }

    return hasPermission;
  }

  hasPermission$(category: RoleCategories, permission: string): Observable<boolean> {
    return this._initialized$.pipe(
      first(),
      switchMap(() => {
        return of(this.hasPermissionImpl(category, permission));
      }),
    );
  }

  /**
   * Check the authentication status
   */
  check(): Observable<boolean> {
    return this._isLoggedIn$;
  }

  isInitialized(): Observable<boolean> {
    return this._initialized$.asObservable();
  }
}
