var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FieldData, RecordTypeData } from '../meta-data-decorators';
import { FilterType } from '../../data-filters/models';
import { RoleCategories } from '../../roleEnums';
import { TrailMapType } from '../../trail-maps/models';
export var PolicyAuditDisputeStatus;
(function (PolicyAuditDisputeStatus) {
    PolicyAuditDisputeStatus["open"] = "open";
    PolicyAuditDisputeStatus["closed"] = "closed";
})(PolicyAuditDisputeStatus || (PolicyAuditDisputeStatus = {}));
const recordTypeId = 'PolicyAuditDispute';
let PolicyAuditDispute = class PolicyAuditDispute {
    id;
    createUserId;
    modifiedUserId;
    deleteUserId;
    createUser;
    createdUTC;
    modifiedUser;
    modifiedUTC;
    deleteUser;
    deletedUTC;
    policyAuditId;
    policyAudit;
    policyholderId;
    policyholder;
    policyId;
    policy;
    closedUTC;
    status;
};
__decorate([
    FieldData({ recordTypeId, label: 'ID', fieldType: 'uuid' })
], PolicyAuditDispute.prototype, "id", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], PolicyAuditDispute.prototype, "createUserId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], PolicyAuditDispute.prototype, "modifiedUserId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], PolicyAuditDispute.prototype, "deleteUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created By', fieldType: 'User' })
], PolicyAuditDispute.prototype, "createUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created On', fieldType: 'datetime' })
], PolicyAuditDispute.prototype, "createdUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified By', fieldType: 'User' })
], PolicyAuditDispute.prototype, "modifiedUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified On', fieldType: 'datetime' })
], PolicyAuditDispute.prototype, "modifiedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted By', fieldType: 'User' })
], PolicyAuditDispute.prototype, "deleteUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted On', fieldType: 'datetime' })
], PolicyAuditDispute.prototype, "deletedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', referenceType: 'PolicyAudit' })
], PolicyAuditDispute.prototype, "policyAuditId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'PolicyAudit' })
], PolicyAuditDispute.prototype, "policyAudit", void 0);
__decorate([
    FieldData({
        recordTypeId,
        fieldType: 'uuid',
        label: 'Policyholder Id',
        referenceType: 'PolicyHolder',
    })
], PolicyAuditDispute.prototype, "policyholderId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'PolicyHolder', label: 'Policyholder' })
], PolicyAuditDispute.prototype, "policyholder", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', referenceType: 'Policy' })
], PolicyAuditDispute.prototype, "policyId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'Policy' })
], PolicyAuditDispute.prototype, "policy", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'datetime', label: 'Closed On' })
], PolicyAuditDispute.prototype, "closedUTC", void 0);
__decorate([
    FieldData({
        recordTypeId,
        fieldType: 'codelist',
        codeList: [
            { code: PolicyAuditDisputeStatus.open, description: 'Open' },
            { code: PolicyAuditDisputeStatus.closed, description: 'Closed' }
        ],
    })
], PolicyAuditDispute.prototype, "status", void 0);
PolicyAuditDispute = __decorate([
    RecordTypeData({
        id: recordTypeId,
        hasBackingTable: true,
        displayNameSingular: 'Policy Audit Dispute',
        displayNamePlural: 'Policy Audit Disputes',
        supports: {
            views: true,
            notes: true,
            files: true,
            tasks: true,
            docTemplates: true,
        },
        trailMapTypes: [TrailMapType.DataEntry, TrailMapType.Detail, TrailMapType.HomePage],
        security: {
            useDefaultPersonaSecurity: true,
            allowCrossPolicyholderRecords: false,
        },
        dataModelCustomization: {
            allowCustomFields: true,
        },
        usesNewDataModel: true,
        roleCategory: RoleCategories.PolicyAuditDisputes,
        lookupProperties: {
            resultProperties: ['policy.policyNumber', 'policyholder.displayName', 'policyholder.tenantNumber'],
            filterProperties: ['policy.policyNumber', 'policyholder.displayName', 'policyholder.tenantNumber'],
        },
        workflow: {
            enabled: true,
            generalEvents: true,
            otherEvents: [
                'POLICYAUDITDISPUTE-CLOSED',
                'POLICYAUDITDISPUTE-REOPENED'
            ],
        },
        quickFilterFields: [
            {
                recordType: recordTypeId,
                fieldPath: 'policyholder.displayName',
                filterType: FilterType.Contains,
            },
            {
                recordType: recordTypeId,
                fieldPath: 'policy.policyNumber',
                filterType: FilterType.Equals,
            },
            {
                recordType: recordTypeId,
                fieldPath: 'status',
                filterType: FilterType.Equals,
            },
        ],
        recordHistoryConfig: {
            enable: true,
        },
    })
], PolicyAuditDispute);
export { PolicyAuditDispute };
