var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FieldData, RecordTypeData } from '../meta-data-decorators';
import { RoleCategories } from '../../roleEnums';
import { TrailMapType } from '../../trail-maps/models';
const recordTypeId = 'LossRunHistory';
let LossRunHistory = class LossRunHistory {
    policyholderId;
    policyHolder;
    childPolicyHolderId;
    childPolicyHolder;
    disableDateFilter;
    lossRunStartDate;
    lossRunEndDate;
    createUserId;
    modifiedUserId;
    deleteUserId;
    createUser;
    createdUTC;
    modifiedUser;
    modifiedUTC;
    deleteUser;
    deletedUTC;
};
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', jsonStorage: false })
], LossRunHistory.prototype, "policyholderId", void 0);
__decorate([
    FieldData({
        recordTypeId,
        fieldType: 'PolicyHolder',
        label: 'Policyholder',
        jsonStorage: true,
    })
], LossRunHistory.prototype, "policyHolder", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', jsonStorage: false })
], LossRunHistory.prototype, "childPolicyHolderId", void 0);
__decorate([
    FieldData({
        recordTypeId,
        fieldType: 'PolicyHolder',
        label: 'Child Policyholder',
        jsonStorage: true,
    })
], LossRunHistory.prototype, "childPolicyHolder", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'boolean', jsonStorage: false })
], LossRunHistory.prototype, "disableDateFilter", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Loss Run Start Date', fieldType: 'datetime' })
], LossRunHistory.prototype, "lossRunStartDate", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Loss Run End Date', fieldType: 'datetime' })
], LossRunHistory.prototype, "lossRunEndDate", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], LossRunHistory.prototype, "createUserId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], LossRunHistory.prototype, "modifiedUserId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], LossRunHistory.prototype, "deleteUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created By', fieldType: 'User' })
], LossRunHistory.prototype, "createUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created On', fieldType: 'datetime' })
], LossRunHistory.prototype, "createdUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified By', fieldType: 'User' })
], LossRunHistory.prototype, "modifiedUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified On', fieldType: 'datetime' })
], LossRunHistory.prototype, "modifiedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted By', fieldType: 'User' })
], LossRunHistory.prototype, "deleteUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted On', fieldType: 'datetime' })
], LossRunHistory.prototype, "deletedUTC", void 0);
LossRunHistory = __decorate([
    RecordTypeData({
        id: recordTypeId,
        hasBackingTable: true,
        displayNameSingular: 'Loss Run History',
        displayNamePlural: 'Loss Run Histories',
        workflow: {
            enabled: true,
            generalEvents: true,
        },
        dataModelCustomization: {
            allowCustomFields: true,
            preventAddCustomFields: true,
        },
        trailMapTypes: [TrailMapType.HomePage, TrailMapType.Detail],
        usesNewDataModel: true,
        roleCategory: RoleCategories.LossRuns,
        supports: {
            views: true,
            files: true,
            docTemplates: true,
            globalSearch: false,
        },
    })
], LossRunHistory);
export { LossRunHistory };
