export const DefaultSystemTrailMaps = {
    Address: '01911444-0a17-725c-880f-eb4b9c4b0402',
};
export var TrailMapType;
(function (TrailMapType) {
    TrailMapType["HomePage"] = "HomePage";
    TrailMapType["Detail"] = "Detail";
    TrailMapType["DataEntry"] = "DataEntry";
    TrailMapType["DataEntryStart"] = "DataEntryStart";
    TrailMapType["QuickAdd"] = "QuickAdd";
})(TrailMapType || (TrailMapType = {}));
export var TrailMapScopeType;
(function (TrailMapScopeType) {
    TrailMapScopeType["User"] = "User";
    TrailMapScopeType["Tenant"] = "Tenant";
    TrailMapScopeType["System"] = "System";
})(TrailMapScopeType || (TrailMapScopeType = {}));
export var TrailMapMode;
(function (TrailMapMode) {
    TrailMapMode["Module"] = "Module";
    TrailMapMode["Admin"] = "Admin";
})(TrailMapMode || (TrailMapMode = {}));
export var TrailMapRequired;
(function (TrailMapRequired) {
    TrailMapRequired["Required"] = "Required";
    TrailMapRequired["NotRequired"] = "NotRequired";
    TrailMapRequired["ConditionallyRequired"] = "ConditionallyRequired";
})(TrailMapRequired || (TrailMapRequired = {}));
