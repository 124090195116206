import {
  HttpErrorResponse,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, from, Observable, of, Subject, switchMap, tap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { SessionUrlService } from './session-url.service';

@Injectable({ providedIn: 'root' })
export class AuthInterceptor implements HttpInterceptor {
  refreshTokenInProgress = false;
  tokenRefreshedSource = new Subject<void>();
  tokenRefreshed$ = this.tokenRefreshedSource.asObservable();

  constructor(
    private authService: AuthService,
    private urlService: SessionUrlService,
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (!this.shouldModify(req)) {
      return next.handle(req);
    }
    return from(this.modifyRequest(req)).pipe(
      switchMap((req) => {
        return next.handle(req).pipe(
          catchError((error) => {
            return this.handleResponseError(error, req, next);
          }),
        );
      }),
    );
  }

  handleResponseError(
    error: HttpErrorResponse,
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<any> {
    // Invalid token error
    if (error.status === 401 && this.shouldRefreshToken(request)) {
      console.warn('401 response, refreshing token');
      return this.refreshToken().pipe(
        switchMap(async () => {
          console.info('401 response, token refreshed');
          request = await this.modifyRequest(request);
          return next.handle(request);
        }),
        catchError((e) => {
          console.error('401 response, token refresh failed', e);
          return throwError(() => e);
        }),
      );
    }
    return throwError(() => error);
  }

  shouldRefreshToken(request: HttpRequest<any>) {
    const authPaths = ['/extended-user-details'];
    return authPaths.filter(f => request.url.includes(f)).length === 0;
  }

  refreshToken(): Observable<string | null> {
    if (this.refreshTokenInProgress) {
      return new Observable((observer) => {
        this.tokenRefreshed$.subscribe(() => {
          observer.next();
          observer.complete();
        });
      });
    }
    this.refreshTokenInProgress = true;

    return from(this.authService.getLatestToken()).pipe(
      tap(() => {
        this.refreshTokenInProgress = false;
        this.tokenRefreshedSource.next();
      }),
      catchError(() => {
        this.refreshTokenInProgress = false;
        return of(null);
      }),
    );
  }

  async modifyRequest(req: HttpRequest<any>) {
    const token = await this.authService.getLatestToken();
    const tenant = this.authService.getTenant() ?? this.urlService.getTenantFromCurrentUrl();

    // If the access token didn't expire, add the Authorization header.  If it did expire don't add so it forces 401
    // catch and delete the access token from the local storage while logging the user out from the app.
    if (!token) {
      return req;
    }
    if (req.headers.has('Authorization') && req.headers.has('X-Tb-Tenant')) {
      return req;
    }

    let headers: HttpHeaders;
    headers = req.headers.set('Authorization', token);
    headers = headers.set('X-Tb-Tenant', tenant ?? 'none');
    if (environment && !!environment.sourceSHA) {
      headers = headers.set('X-Tb-Sha', environment.sourceSHA);
    }
    if (!!window.location.href) {
      headers = headers.set('X-Tb-Referrer', window.location.href);
    }
    return req.clone({ headers: headers });
  }

  shouldModify(req: HttpRequest<any>) {
    if (req.headers.has('X-NO-AUTH')) {
      return false;
    }
    let url = req.url;

    let apiPath = environment.apiOriginPath;
    if (!apiPath.startsWith('/')) {
      apiPath = `/${apiPath}`;
    }

    if (!url.toLowerCase().startsWith('http')) {
      if (!url.startsWith('/')) {
        url = `/${url}`;
      }
      return url.toLowerCase().startsWith(apiPath.toLowerCase());
    }

    return (
      url.toLowerCase().includes(window.location.hostname.toLowerCase()) &&
      url.toLowerCase().includes(apiPath.toLowerCase())
    );
  }
}
