var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FieldData, RecordTypeData } from '../meta-data-decorators';
export var ErrorSourceEnum;
(function (ErrorSourceEnum) {
    ErrorSourceEnum["http"] = "http";
    ErrorSourceEnum["queue"] = "queue";
    ErrorSourceEnum["other"] = "other";
})(ErrorSourceEnum || (ErrorSourceEnum = {}));
const recordTypeId = 'ErrorLog';
let ErrorLog = class ErrorLog {
    //pk
    id;
    ts;
    errorSource;
    queueName;
    errorStack;
    requestContext;
    message;
    awsContext;
    sessionUserId;
    sqsRecord;
    referrer;
};
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], ErrorLog.prototype, "id", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'datetime' })
], ErrorLog.prototype, "ts", void 0);
__decorate([
    FieldData({
        recordTypeId, fieldType: 'codelist', codeList: [
            { code: ErrorSourceEnum.http, description: 'HTTP' },
            { code: ErrorSourceEnum.queue, description: 'Queue' },
            { code: ErrorSourceEnum.other, description: 'Other' },
        ]
    })
], ErrorLog.prototype, "errorSource", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string' })
], ErrorLog.prototype, "queueName", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'jsonb', jsonStorage: true })
], ErrorLog.prototype, "errorStack", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'jsonb', jsonStorage: true })
], ErrorLog.prototype, "requestContext", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string' })
], ErrorLog.prototype, "message", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'jsonb', jsonStorage: true })
], ErrorLog.prototype, "awsContext", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string' })
], ErrorLog.prototype, "sessionUserId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'jsonb', jsonStorage: true })
], ErrorLog.prototype, "sqsRecord", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string', jsonStorage: true })
], ErrorLog.prototype, "referrer", void 0);
ErrorLog = __decorate([
    RecordTypeData({
        id: recordTypeId,
        hasBackingTable: true,
        usesNewDataModel: true,
        displayNameSingular: 'Error Log',
        displayNamePlural: 'Error Logs',
    })
], ErrorLog);
export { ErrorLog };
