<p-treeSelect
  #tree
  [containerStyleClass]="'w-full ' + (this.denseLayout ? 'py-0 h-10' : '')"
  [labelStyle]="!this.denseLayout ? { 'padding-top': '9px', 'padding-bottom': '8px' } : {}"
  [options]="(fieldNodes$ | async) ?? []"
  [placeholder]="placeholder"
  [filter]="true"
  (onFilter)="onFilter($event)"
  [filterInputAutoFocus]="true"
  (onNodeSelect)="onNodeSelected($event)"
  [(ngModel)]="selectedNodes"></p-treeSelect>
