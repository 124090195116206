export function getObjValueFromPath(model: any, path: string) {
  try {
    if (model[path] !== undefined) {
      return model[path];
    }
    return path.split('.').reduce((a, b) => a[b], model);
  } catch (err) {
    //console.error(`Unable to infer path value ${path}`);
    return null;
  }
}

export function keyPathExists(obj: any, path: string): boolean {
  const pathParts = path.split('.');
  if (!pathParts || pathParts.length === 0) { return false; }
  let currObj = obj;
  for (const pathPart of pathParts) {
    if (!currObj) { continue; }
    if (!Object.keys(currObj)?.includes(pathPart)) {
      return false;
    }
    currObj = currObj[pathPart];
  }
  return true;
}

export function jsonParseToObject(input: string | null | undefined, reviver?: any): any {
  if (input === null || input === undefined) { return input; }
  const obj = JSON.parse(input, reviver);
  if (typeof obj === 'object') {
    return obj;
  }
  return JSON.parse(obj, reviver);
}