<form [formGroup]="this.form">
  <div class="flex flex-row justify-between w-full pb-2">
    <div class="flex flex-col gap-2 w-full">
      <div class="flex flex-row justify-center w-full">
        <mat-button-toggle-group
          *ngIf="(allowOperators$ | async) && index > 0"
          appearance="standard"
          class="text-lg p-2"
          formControlName="operator">
          <mat-button-toggle *ngFor="let operator of operators" [value]="operator.value">{{
            operator.text
          }}</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <div *ngIf="this.form.get('type')?.value === FormulaValueType.Field">
        <tb-datamodel-field-treeselect
          mode="Filters"
          [rootRecordTypeId$]="this.recordTypeId$"
          (fieldchanged)="fieldChanged($event)"
          [isRequired]="true"
          [inputValue$]="field$"
          [rootFieldsOnly]="true"
          [includeListFields]="true">
        </tb-datamodel-field-treeselect>
        <div
          class="text-sm text-secondary ml-2 my-1 flex-wrap"
          *ngIf="additionalLabel$ | async as additionalLabel">
          {{ additionalLabel }}
        </div>
      </div>
      <mat-form-field *ngIf="this.form.get('type')?.value === FormulaValueType.Date">
        <input
          matInput
          [matDatepicker]="dateValue"
          [maskito]="options"
          formControlName="value"
          matTooltip="." />
        <mat-datepicker-toggle matSuffix [for]="dateValue"></mat-datepicker-toggle>
        <mat-datepicker #dateValue></mat-datepicker>
      </mat-form-field>
      <mat-form-field
        *ngIf="this.form.get('type')?.value === FormulaValueType.Text"
        class="no-margin-bottom flex-grow fuse-mat-dense">
        <input class="flex-grow" matInput formControlName="value" placeholder="Enter Value..." />
      </mat-form-field>
      <mat-form-field
        *ngIf="this.form.get('type')?.value === FormulaValueType.Number"
        class="no-margin-bottom flex-grow fuse-mat-dense">
        <input class="flex-grow" matInput formControlName="value" type="number" placeholder="100" />
      </mat-form-field>
      <div
        *ngIf="this.form.get('type')?.value === FormulaValueType.Formula"
        class="no-margin-bottom flex-grow">
        <tb-formula
          [recordTypeId$]="recordTypeId$"
          [formula]="getFormulaValue(component)"
          [index]="index"
          [depth]="depth + 1"
          [outputFormat$]="outputFormat$"
          (formulaUpdated)="this.onFormulaUpdated($event)"></tb-formula>
      </div>
    </div>
    <button
      mat-icon-button
      [color]="'warn'"
      [matTooltip]="'Remove Component'"
      (click)="onDeleteComponent()">
      <mat-icon [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
    </button>
  </div>
</form>
