<mat-form-field
  class="w-full"
  [floatLabel]="this.floatLabel"
  [class]="matFormFieldClasses ?? ''"
  [ngClass]="{ 'fuse-mat-dense': this.denseField }">
  <mat-label *ngIf="!!label" [class]="labelClass ?? ''">{{ label }}</mat-label>
  <mat-icon
    *ngIf="!!this.ngControl.value || this.readonly"
    matPrefix
    class="icon-size-5"
    [svgIcon]="'heroicons_outline:magnifying-glass'"></mat-icon>
  <button
    *ngIf="!!!this.ngControl.value && !this.readonly"
    matPrefix
    mat-icon-button
    [matTooltip]="'Search ' + (this.label ?? '')"
    aria-label="Search"
    (click)="triggerSearch()"
    type="button">
    <mat-icon class="icon-size-5" [svgIcon]="'heroicons_outline:magnifying-glass'"></mat-icon>
  </button>
  <input
    type="text"
    #inputBox
    matInput
    [readonly]="this.readonly"
    [formControl]="ngControl.control"
    [matAutocomplete]="tbAutoComplete"
    [placeholder]="placeHolder ?? ''" />
  <span swRight swSm [swIsLoading]="this.LOADING_KEY"></span>
  <button
    *ngIf="this.ngControl.value && !this.readonly"
    matSuffix
    mat-icon-button
    [matTooltip]="'Clear ' + (this.label ?? '')"
    aria-label="Clear"
    (click)="onClick()"
    type="button">
    <mat-icon>close</mat-icon>
  </button>
  <mat-autocomplete
    #tbAutoComplete="matAutocomplete"
    [displayWith]="displayWith"
    (optionSelected)="onSelected($event)">
    <ng-container *ngIf="filteredItems$ | async as filteredItems">
      <mat-option
        *ngFor="let item of filteredItems"
        [value]="item"
        tbLongTextOption="{{ displayItem(item) }}">
        <ng-container
          *ngIf="this.displayTemplate"
          [ngTemplateOutlet]="this.displayTemplate"
          [ngTemplateOutletContext]="{ item: item }"></ng-container>
        <div *ngIf="this.displayTemplate === undefined" class="flex justify-between w-full">
          {{ displayItem(item) }}
          <span *ngIf="showStatus()" class="italic opacity-50 uppercase">{{ item.status }} </span>
        </div>
      </mat-option>
      <mat-option *ngIf="filteredItems !== undefined && filteredItems.length === 0" disabled>
        <span>No matches found</span>
      </mat-option>
    </ng-container>
  </mat-autocomplete>
  <mat-error *ngIf="this.errorMessage$ | async as errorMessage">{{ errorMessage }}</mat-error>
  <mat-error *ngIf="this.ngControl.control.hasError('autoCompleteValueMustMatch')">
    You must select a valid value
  </mat-error>
  <mat-error *ngIf="this.ngControl.control.hasError('required')"> A value is required </mat-error>
  <mat-hint *ngIf="!!this.hint">{{ this.hint }}</mat-hint>
</mat-form-field>
