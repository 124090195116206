import { inject, Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NgDompurifyPipe } from '@tinkoff/ng-dompurify';

@Pipe({ name: 'sanitizeHtml', pure: true })
export class SanitizeHtmlPipe implements PipeTransform {
  domPurifyPipe = inject(NgDompurifyPipe);
  constructor(private _sanitizer: DomSanitizer) { }

  transform(value: string): SafeHtml {
    if (!value) {
      return value;
    }
    return this._sanitizer.sanitize(SecurityContext.NONE, this.domPurifyPipe.transform(value)) as SafeHtml;
  }
}
