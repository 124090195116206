import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateFilterConditionsPipe, TranslateFilterGroupPipe } from './pipes/filter.pipes';



@NgModule({
  declarations: [
    TranslateFilterGroupPipe,
    TranslateFilterConditionsPipe
  ],
  exports: [
    TranslateFilterGroupPipe,
    TranslateFilterConditionsPipe
  ],
  imports: [
    CommonModule
  ],
  providers: [
    TranslateFilterGroupPipe,
    TranslateFilterConditionsPipe
  ]
})
export class FiltersModule { }
