import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
  Input,
  Renderer2,
} from '@angular/core';

import { MatTooltip } from '@angular/material/tooltip';
@Directive({
  selector: '[tbLongTextOption]',
  providers: [MatTooltip],
})
export class TbLongTextOptionDirective implements AfterViewInit {
  @Input() tbLongTextOption: string;

  constructor(private el: ElementRef, private renderer: Renderer2, private tooltip: MatTooltip) {}

  ngAfterViewInit(): void {
    // Delay processing to allow Angular Material to render its internal structure
    setTimeout(() => {
      const matOptionText = this.el.nativeElement.querySelector('.mat-option-text');
      if (matOptionText) {
        this.applyTruncate(matOptionText);
      } else {
        console.warn('tbLongTextOption: .mat-option-text not found');
      }
    });
  }

  @HostListener('mouseover') mouseover() {
    this.tooltip.show();
  }
  @HostListener('mouseleave') mouseleave() {
    this.tooltip.hide();
  }
  private applyTruncate(element: HTMLElement): void {
    // Preserve existing text content
    const content = this.tbLongTextOption || element.textContent?.trim() || '';
    this.tooltip.message = content;
    // Set tooltip
    this.tooltip.position = 'below';

    // Wrap content in a <span class="truncate">
    const span = this.renderer.createElement('span');
    this.renderer.addClass(span, 'truncate');
    this.renderer.setProperty(span, 'textContent', content);

    // Clear existing content and append <span>
    while (element.firstChild) {
      element.removeChild(element.firstChild);
    }
    this.renderer.appendChild(element, span);
  }
}
