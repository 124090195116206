var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FieldData, RecordTypeData } from '../meta-data-decorators';
import { RoleCategories } from '../../roleEnums';
import { TrailMapType } from '../../trail-maps/models';
export var QuoteStatus;
(function (QuoteStatus) {
    QuoteStatus["InReview"] = "inreview";
    QuoteStatus["Approved"] = "approved";
    QuoteStatus["Declined"] = "declined";
    QuoteStatus["Denied"] = "denied";
    QuoteStatus["Accepted"] = "accepted";
    QuoteStatus["InfoRequested"] = "inforequested";
    QuoteStatus["Bound"] = "bound";
})(QuoteStatus || (QuoteStatus = {}));
export var QuoteType;
(function (QuoteType) {
    QuoteType["Submission"] = "submission";
    QuoteType["Endorsement"] = "endorsement";
    QuoteType["Renewal"] = "renewal";
    QuoteType["Audit"] = "audit";
})(QuoteType || (QuoteType = {}));
const recordTypeId = 'Quote';
let Quote = class Quote {
    id;
    policyNumber;
    createUserId;
    createdUTC;
    modifiedUser;
    modifiedUTC;
    deleteUser;
    deletedUTC;
    submissionId;
    submission;
    endorsementId;
    endorsement;
    renewalId;
    renewal;
    //scope of coverage
    payrollClassCodes;
    //display only -- possibly just extend and use payrollClassCode
    payrollDetails;
    //display only
    subtotalDetails;
    //actual cost structure used by policy billing
    costDetails;
    total;
    quoteNumber;
    status;
    quoteType;
    quoteName;
    approvedDate;
    lockedUntilDate;
    agentId;
    agent;
    agencyId;
    agency;
    policyholderId;
    policyholder;
    applicant;
    underwriterId;
    underwriter;
    deniedReason;
    declinedReason;
    reasonNotes;
    effectiveDate;
    expirationDate;
    billingScheduleTypeId;
    writtenPremium;
    deltaTotal;
    annualContribution;
    policyId;
    policy;
    xMod;
    ignoreBilling;
    sourcePolicyId;
    sourcePolicy;
    ratingFormulaVersionId;
    earnedPremium;
    totalIncurred;
    lossRatio;
    totalDue;
    locationBasedPayroll;
    policyLocations;
    submissionDate;
    submittedById;
    submittedBy;
};
__decorate([
    FieldData({ recordTypeId, label: 'Quote ID', fieldType: 'uuid' })
], Quote.prototype, "id", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true })
], Quote.prototype, "policyNumber", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created By' })
], Quote.prototype, "createUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created On', fieldType: 'datetime' })
], Quote.prototype, "createdUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified By', fieldType: 'User' })
], Quote.prototype, "modifiedUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified On', fieldType: 'datetime' })
], Quote.prototype, "modifiedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted By', fieldType: 'User' })
], Quote.prototype, "deleteUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted On', fieldType: 'datetime' })
], Quote.prototype, "deletedUTC", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Submission ID',
        fieldType: 'uuid',
        referenceType: 'Submission',
    })
], Quote.prototype, "submissionId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Submission', fieldType: 'Submission', jsonStorage: false })
], Quote.prototype, "submission", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Endorsement ID',
        fieldType: 'uuid',
        referenceType: 'Endorsement',
    })
], Quote.prototype, "endorsementId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Endorsement', fieldType: 'Endorsement', jsonStorage: false })
], Quote.prototype, "endorsement", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Renewal ID', fieldType: 'uuid', referenceType: 'Renewal' })
], Quote.prototype, "renewalId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Renewal', fieldType: 'Renewal', jsonStorage: false })
], Quote.prototype, "renewal", void 0);
__decorate([
    FieldData({
        recordTypeId,
        isList: true,
        jsonStorage: true,
        fieldType: 'PayrollClassCode',
    })
], Quote.prototype, "payrollClassCodes", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Payroll Details',
        fieldType: 'QuotePayrollDetail',
        isList: true,
        jsonStorage: true,
    })
], Quote.prototype, "payrollDetails", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Subtotal Details',
        fieldType: 'QuoteSubtotalDetail',
        isList: true,
        jsonStorage: true,
    })
], Quote.prototype, "subtotalDetails", void 0);
__decorate([
    FieldData({
        recordTypeId,
        fieldType: 'jsonb',
        jsonStorage: true,
        disableField: true,
        isList: true,
    })
], Quote.prototype, "costDetails", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Total', fieldType: 'currency' })
], Quote.prototype, "total", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Quote Number' })
], Quote.prototype, "quoteNumber", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Status',
        formatter: (v) => {
            switch (v) {
                case QuoteStatus.InReview:
                    return 'In Underwriter Review';
                case QuoteStatus.InfoRequested:
                    return 'More Info Requested';
                default:
                    return `${v[0].toUpperCase()}${v.slice(1)}`;
            }
        },
        pathOverride: `case when "{tableAlias}"."status" = 'inreview' then 'In Underwriter Review' when "{tableAlias}"."status" = 'inforequested' then 'Info Requested' else initcap("{tableAlias}"."status") end`,
    })
], Quote.prototype, "status", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Quote Type',
    })
], Quote.prototype, "quoteType", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Quote Name',
    })
], Quote.prototype, "quoteName", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Approved Date', fieldType: 'datetime' })
], Quote.prototype, "approvedDate", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Locked Until Date',
        fieldType: 'datetime',
    })
], Quote.prototype, "lockedUntilDate", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Agent ID', fieldType: 'string' })
], Quote.prototype, "agentId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Agent', fieldType: 'Agent', jsonStorage: true })
], Quote.prototype, "agent", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Agency ID', fieldType: 'uuid' })
], Quote.prototype, "agencyId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Agency', fieldType: 'Agency' })
], Quote.prototype, "agency", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Policyholder ID', fieldType: 'uuid' })
], Quote.prototype, "policyholderId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Policyholder', fieldType: 'PolicyHolder', jsonStorage: true })
], Quote.prototype, "policyholder", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Applicant', fieldType: 'Applicant', jsonStorage: true })
], Quote.prototype, "applicant", void 0);
__decorate([
    FieldData({ recordTypeId })
], Quote.prototype, "underwriterId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Underwriter', fieldType: 'User' })
], Quote.prototype, "underwriter", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Denied Reason',
        codeSet: 'quoteDeniedReasons',
    })
], Quote.prototype, "deniedReason", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Declined Reason',
        codeSet: 'quoteDeclinedReasons',
    })
], Quote.prototype, "declinedReason", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Reason Notes' })
], Quote.prototype, "reasonNotes", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Effective Date', fieldType: 'date' })
], Quote.prototype, "effectiveDate", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Expiration Date', fieldType: 'date' })
], Quote.prototype, "expirationDate", void 0);
__decorate([
    FieldData({
        recordTypeId,
        fieldType: 'uuid',
        label: 'Billing Schedule Type ',
        referenceType: 'PolicyBillingScheduleType',
        disableField: true,
    })
], Quote.prototype, "billingScheduleTypeId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency', jsonStorage: true })
], Quote.prototype, "writtenPremium", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency', jsonStorage: true })
], Quote.prototype, "deltaTotal", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency', jsonStorage: true })
], Quote.prototype, "annualContribution", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Policy ID', fieldType: 'uuid' })
], Quote.prototype, "policyId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Policy', fieldType: 'Policy' })
], Quote.prototype, "policy", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'decimal', label: 'Experience Modifier' })
], Quote.prototype, "xMod", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'boolean', label: 'Ignore Billing' })
], Quote.prototype, "ignoreBilling", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Source Policy ID', fieldType: 'uuid', jsonStorage: true })
], Quote.prototype, "sourcePolicyId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Source Policy', fieldType: 'Policy', jsonStorage: true })
], Quote.prototype, "sourcePolicy", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', hidden: ['All'] })
], Quote.prototype, "ratingFormulaVersionId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'decimal', label: 'Earned Premium', jsonStorage: true })
], Quote.prototype, "earnedPremium", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency', label: 'Total Incurred', jsonStorage: true })
], Quote.prototype, "totalIncurred", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'decimal', label: 'Loss Ratio', jsonStorage: true })
], Quote.prototype, "lossRatio", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency', jsonStorage: true })
], Quote.prototype, "totalDue", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Location Based Payroll',
        fieldType: 'boolean',
        jsonStorage: true,
    })
], Quote.prototype, "locationBasedPayroll", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Policy Locations',
        isList: true,
        fieldType: 'Location',
        jsonStorage: true,
    })
], Quote.prototype, "policyLocations", void 0);
Quote = __decorate([
    RecordTypeData({
        id: recordTypeId,
        hasBackingTable: true,
        displayNameSingular: 'Quote',
        displayNamePlural: 'Quotes',
        supports: {
            views: true,
            files: true,
            docTemplates: true,
        },
        dataModelCustomization: {
            allowCustomFields: true,
        },
        trailMapTypes: [TrailMapType.HomePage, TrailMapType.Detail],
        roleCategory: RoleCategories.Quotes,
        lookupProperties: {
            resultProperties: ['quoteNumber', 'total', 'createdUTC'],
            filterProperties: ['quoteNumber'],
        },
        usesNewDataModel: true,
        recordHistoryConfig: {
            enable: true,
        },
        workflow: {
            enabled: true,
            generalEvents: true,
            otherEvents: [
                'QUOTE-APPROVED',
                'QUOTE-DENIED',
                'QUOTE-ACCEPTED',
                'QUOTE-DECLINED',
                'QUOTE-BOUND',
                'QUOTE-REASSIGNED',
                'QUOTE-INFOREQUESTED',
                'QUOTE-INREVIEW',
            ],
        },
    })
], Quote);
export { Quote };
