<div class="flex flex-col">
  <div class="flex flex-row">
    <div *ngIf="this.filterStore.isCollapsible$ | async">
      <button
        mat-button
        [color]="'primary'"
        (click)="toggleFilter()"
        class="rounded-md mr-2"
        *ngIf="!this.iconOnly">
        <mat-icon
          class="icon-size-5 tb-data-filter-panel-icon"
          [svgIcon]="this.expanded ? 'mat_solid:filter_alt' : 'mat_outline:filter_alt'"></mat-icon>
        Filter Results
      </button>
      <button
        mat-icon-button
        [color]="'primary'"
        (click)="toggleFilter()"
        matTooltip="Filter Results"
        *ngIf="this.iconOnly">
        <mat-icon
          class="icon-size-5 tb-data-filter-panel-icon"
          [svgIcon]="this.expanded ? 'mat_solid:filter_alt' : 'mat_outline:filter_alt'"></mat-icon>
      </button>
    </div>
  </div>
  <div class="filterDetailPanel mb-1" [ngClass]="{ 'bg-slate-50': this.insideContentPanel }">
    <div *ngIf="this.showViews" class="ml-1 mt-2 py-2 px-4">
      <mat-form-field class="fuse-mat-dense no-wrapper-mb w-100">
        <mat-select [formControl]="viewControl" placeholder="Apply additional view filter">
          <mat-option
            *ngFor="let view of this.views$ | async"
            [value]="view.id"
            tbLongTextOption="{{ view.displayName }}"
            >{{ view.displayName }}
          </mat-option>
        </mat-select>
        <button
          *ngIf="this.viewControl?.value"
          matSuffix
          mat-icon-button
          matTooltip="Clear View"
          aria-label="Clear"
          (click)="this.viewControl?.setValue('')"
          type="button">
          <mat-icon class="icon-size-4">close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div
      class="w-full py-2 px-4"
      *ngIf="this.filterStore.quickSearchFields$ | async"
      [formGroup]="quickSearchForm"
      autocomplete="off">
      <tb-data-filter-quick-search [formGroup]="quickSearchForm"></tb-data-filter-quick-search>
    </div>
    <div
      class="px-6 flex flex-row text-secondary text-sm gap-2"
      *ngIf="this.filterStore.quickSearchFilterDescription$ | async as quickSearchDescription">
      <div>Filter Applied:</div>
      <div [innerHtml]="quickSearchDescription"></div>
    </div>
    <div class="flex flex-row py-2 px-4" *ngIf="!this.disableAddFilter">
      <mat-chip-list #chipList aria-label="data filter" class="flex flex-col">
        <ng-container *ngFor="let filter of this.filterStore.filters$ | async; let index = index">
          <mat-chip
            (removed)="removeFilter(index)"
            (click)="filterSelected($event, filter, index)"
            class="cursor-pointer">
            <div class="flex flex-row gap-1 font-light">
              <span *ngFor="let part of filter.descriptionParts" [class]="part.classes">{{
                part.text
              }}</span>
            </div>
            <button matChipRemove class="-mt-1">
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip>
        </ng-container>
        <button
          mat-button
          [color]="'accent'"
          matTooltip="Add Filter Field"
          class="items-end"
          (click)="openFilterDialog($event)">
          <mat-icon [svgIcon]="'heroicons_outline:plus'" class="icon-size-4 mr-1 -ml-1"></mat-icon>
          Add Filter Field
        </button>
      </mat-chip-list>
    </div>
    <div class="flex flex-row py-2" *ngIf="this.disableAddFilter"></div>
  </div>
</div>
