import { Pipe, PipeTransform } from "@angular/core";
import { FilterService } from "../services/filter.service";
import { FilterGroup } from "portal-commons/dist/views/models";
import { FilterCondition } from 'portal-commons/dist/data-filters/models';
import { convert } from 'html-to-text';
import { PrettyLogicalOperator } from "portal-commons/dist/data-filters/models";

@Pipe({ name: 'translateFilterGroup' })
export class TranslateFilterGroupPipe implements PipeTransform {
    constructor(private filterService: FilterService) { }

    transform(filter: FilterGroup, textOnly = false): string | null {
        const result = this.filterService.translateFilterGroup(filter);

        if (textOnly) {
            return convert(result);
        }

        return result;
    }
}

@Pipe({ name: 'translateFilterConditions' })
export class TranslateFilterConditionsPipe implements PipeTransform {
    constructor(private filterService: FilterService) { }

    transform(conditions: FilterCondition[], textOnly = false): string | null {
        const group: FilterGroup = {
            operator: PrettyLogicalOperator.All,
            conditions: conditions,
            groups: []
        };
        const result = this.filterService.translateFilterGroup(group);

        if (textOnly) {
            return convert(result);
        }

        return result;
    }
}
